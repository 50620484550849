import React from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Skeleton from "react-loading-skeleton";

import Link from "components/common/Link";

const LinkGroup = ({ data }) => (
  <Col md={3} className="mb-md-0 mb-3">
    <h5 className="mb-4">{data.label || <Skeleton />}</h5>
    <ul className="list-unstyled">
      {data.links ? (
        data.links.map((link) => (
          <li key={link.id}>
            <Button
              as={Link}
              to={link.to}
              newTab={link.new_tab}
              variant="link"
              className="px-0"
            >
              {link.label}
            </Button>
          </li>
        ))
      ) : (
        <Skeleton count={5} />
      )}
    </ul>
  </Col>
);

export default LinkGroup;
