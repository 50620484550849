import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import Link from "components/common/Link";
import ResponsiveImage from "components/common/ResponsiveImage";

const ReferenceSlider = ({ data }) => {
  const slides = data.references.map((reference) => (
    <Link
      key={reference.id}
      style={{ position: "relative", height: 70 }}
      to={reference.link}
      newTab={reference.new_tab}
    >
      <ResponsiveImage
        className="d-block mx-auto"
        image={reference.image}
        maxHeight={70}
      />
    </Link>
  ));
  return (
    <Carousel
      slides={slides}
      slidesPerPage={5}
      infinite
      keepDirectionWhenDragging
      autoPlay={4000}
      animationSpeed={1000}
      stopAutoPlayOnHover
      breakpoints={{
        480: {
          slidesPerPage: 1,
        },
        576: {
          slidesPerPage: 2,
        },
        768: {
          slidesPerPage: 3,
        },
        992: {
          slidesPerPage: 4,
        },
      }}
    />
  );
};

export default ReferenceSlider;
