import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Section from "components/common/Section";

const Loading = () => {
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadingAnimation(true);
    }, 2000);

    // cleanup function
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="page-loading">
      {showLoadingAnimation ? (
        <Section spacing="large" className="mt-5">
          <Row>
            <Col className="d-flex justify-content-center">
              <Spinner animation="border" role="status" variant="secondary">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Section>
      ) : null}
    </div>
  );
};

export default Loading;
