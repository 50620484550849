import React from "react";
import NavDropdown from "react-bootstrap/NavDropdown";

import NavLink from "components/navigation/NavLink";

const NavItem = ({ data, ...rest }) => {
  if (data.__component === "common.link") {
    return <NavLink data={data} {...rest} />;
  }
  if (data.__component === "common.link-group") {
    return (
      <NavDropdown title={data.label} id={`nav-dropdown-${data.id}`}>
        {data.links.map((dropdownLink) => (
          <NavDropdown.Item
            className="position-relative"
            as="button"
            key={dropdownLink.id}
          >
            <NavLink className="stretched-link" data={dropdownLink} {...rest} />
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    );
  }
  return <></>;
};

export default NavItem;
