import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ResponsiveImage from "components/common/ResponsiveImage";
import Button from "components/common/Button";
import Link from "components/common/Link";

const LargeTitle = ({ data }) => (
  <header>
    {data.image && (
      <ResponsiveImage
        image={data.image}
        cover
        overlay
        className="bst-header-img large d-flex align-items-center"
      >
        <Container>
          <div className="bst-header-flex-container">
            <div className="bst-header-caption">
              <Row>
                <Col md={10} lg={8} xl={6}>
                  <h1>{data.title}</h1>
                  <p className="lead">{data.description}</p>
                </Col>
              </Row>
              {data.buttons && (
                <Row className="mt-4">
                  <Col>
                    {data.buttons.map((button, index) => (
                      <Button
                        key={button.id}
                        as={Link}
                        to={button.link}
                        newTab={button.new_tab}
                        icon={button.icon}
                        variant={button.variant}
                        outline={button.outline}
                        className={`${
                          data.buttons.length > 1
                            ? `${
                                index < data.buttons.length - 1 ? "mr-4 " : ""
                              }mb-3 mb-md-0`
                            : ""
                        } btn-autosize`}
                      >
                        {button.label}
                      </Button>
                    ))}
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Container>
      </ResponsiveImage>
    )}
  </header>
);

export default LargeTitle;
