import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const HtmlHeader = ({ title, description, image }) => {
  const websiteName = process.env.REACT_APP_WEBSITE_NAME;
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL;
  const cmsUrl = process.env.REACT_APP_CMS_URL;
  if (!title) title = process.env.REACT_APP_WEBSITE_DEFAULT_TITLE;
  return (
    <Helmet>
      <title>
        {title} - {websiteName}
      </title>
      <meta property="og:title" content={`${title} - ${websiteName}`} />
      <meta name="twitter:title" content={`${title} - ${websiteName}`} />
      <meta
        property="og:url"
        content={`${websiteUrl}${useLocation().pathname}`}
      />
      {description ? <meta name="description" content={description} /> : null}
      {description ? (
        <meta property="og:description" content={description} />
      ) : null}
      {description ? (
        <meta name="twitter:description" content={description} />
      ) : null}
      {image ? (
        <meta property="og:image" content={`${cmsUrl}${image}`} />
      ) : null}
      {image ? (
        <meta name="twitter:image" content={`${cmsUrl}${image}`} />
      ) : null}
    </Helmet>
  );
};

export default HtmlHeader;
