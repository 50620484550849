import React from "react";
import Nav from "react-bootstrap/Nav";

import Link from "components/common/Link";

const NavLink = ({ data, ...rest }) => {
  return (
    <Nav.Link as={Link} navLink to={data.to} newTab={data.new_tab} {...rest}>
      {data.label}
    </Nav.Link>
  );
};

export default NavLink;
