export default class Matomo {
  constructor(opts) {
    const options = opts;

    options.enableLinkTracking =
      options.enableLinkTracking !== undefined
        ? options.enableLinkTracking
        : true;
    options.trackDocumentTitle =
      options.trackDocumentTitle !== undefined
        ? options.trackDocumentTitle
        : true;
    options.jsFilename =
      options.jsFilename !== undefined ? options.jsFilename : "matomo.js";
    options.phpFilename =
      options.phpFilename !== undefined ? options.phpFilename : "matomo.php";

    this.options = options;

    if (this.options.url === undefined || this.options.siteId === undefined) {
      throw new Error(
        "MatomoTracker cannot be initialized! SiteId and url are mandatory."
      );
    }

    //this.initMatomo();
  }

  initMatomo() {
    if (typeof window !== "undefined") {
      let url = this.options.url;

      if (url.indexOf("http://") !== -1 || url.indexOf("https://") !== -1) {
        url = `${url}/`;
      } else {
        url =
          document.location.protocol === "https:"
            ? `https://${url}/`
            : `http://${url}/`;
      }

      window._paq = window._paq || []; // eslint-disable-line  no-underscore-dangle

      Matomo.push(["requireConsent"]);
      Matomo.push(["setSiteId", this.options.siteId]);
      Matomo.push(["setTrackerUrl", `${url + this.options.phpFilename}`]);

      if (this.options.enableLinkTracking) {
        Matomo.push(["enableLinkTracking"]);
      }

      const scriptElement = document.createElement("script");
      const refElement = document.getElementsByTagName("script")[0];

      scriptElement.type = "text/javascript";
      scriptElement.defer = true;
      scriptElement.async = true;

      let jsFilename = this.options.jsFilename;

      if (
        jsFilename.indexOf("http://") !== 0 &&
        jsFilename.indexOf("https://") !== 0
      ) {
        jsFilename = url + jsFilename;
      }

      scriptElement.src = jsFilename;
      refElement.parentNode.insertBefore(scriptElement, refElement);
    }

    return {
      push: this.push,
      track: this.track,
      connectToHistory: this.connectToHistory,
      disconnectFromHistory: this.disconnectFromHistory,
    };
  }

  static push(args) {
    window._paq.push(args); // eslint-disable-line  no-underscore-dangle
  }

  connectToHistory(history) {
    const prevLoc =
      typeof history.getCurrentLocation === "undefined"
        ? history.location
        : history.getCurrentLocation();
    this.previousPath =
      prevLoc.path || (prevLoc.pathname + prevLoc.search).replace(/^\//, "");
    this.unlistenFromHistory = history.listen((loc) => {
      this.track(loc);
    });

    return history;
  }

  disconnectFromHistory() {
    if (this.unlistenFromHistory) {
      this.unlistenFromHistory();

      return true;
    }

    return false;
  }

  getBasePath() {
    if (typeof this.options.pathBasename !== "undefined") {
      return `${window.location.origin}/${this.options.pathBasename}/`;
    }
    return `${window.location.origin}/`;
  }

  track(loc) {
    if (typeof window === "undefined") {
      return;
    }
    const currentPath =
      loc.path || (loc.pathname + loc.search).replace(/^\//, "");

    if (this.previousPath === currentPath) {
      return;
    }

    if (this.options.trackDocumentTitle) {
      Matomo.push(["setDocumentTitle", document.title]);
    }

    if (this.previousPath) {
      Matomo.push([
        "setReferrerUrl",
        `${this.getBasePath()}${this.previousPath}`,
      ]);
    }
    Matomo.push(["setCustomUrl", `${this.getBasePath()}${currentPath}`]);
    Matomo.push(["trackPageView"]);

    this.previousPath = currentPath;
  }
}
