import React from "react";

import Section from "components/common/Section";
import Info from "components/page/body/Info";
import RichText from "components/page/body/RichText";
import IconContainer from "components/page/body/IconContainer";
import IconCollection from "components/page/body/IconCollection";
import List from "components/page/body/List";
import ImageWithText from "components/page/body/ImageWithText";
import TextWithImage from "components/page/body/TextWithImage";
import ReferenceSlider from "components/page/body/ReferenceSlider";
import ContactForm from "components/page/body/ContactForm";
import PhotoGallery from "components/page/body/PhotoGallery";
import CookieInfo from "components/page/body/CookieInfo";

const BodyComponent = ({ data }) => (
  <Section
    spacing={data.spacing}
    background={data.background}
    noContainer={data.__component === "body.image-with-text"}
  >
    {
      {
        "body.info": <Info data={data} />,
        "body.rich-text": <RichText data={data} />,
        "body.icon-container": <IconContainer data={data} />,
        "body.icon-collection": <IconCollection data={data} />,
        "body.list": <List data={data} />,
        "body.image-with-text": <ImageWithText data={data} />,
        "body.text-with-image": <TextWithImage data={data} />,
        "body.reference-slider": <ReferenceSlider data={data} />,
        "body.contact-form": <ContactForm data={data} />,
        "body.photo-gallery": <PhotoGallery data={data} />,
        "body.cookie-info": <CookieInfo data={data} />,
      }[data.__component]
    }
  </Section>
);

export default BodyComponent;
