import React from "react";
import MarkdownView from "react-showdown";

import Link from "components/common/Link";

const Markdown = ({ content }) => (
  <MarkdownView
    markdown={content}
    options={{ tables: true, emoji: true }}
    components={{ Link }}
  />
);

export default Markdown;
