import React, { createContext, useState } from "react";

export const CookieContext = createContext();

export const CookieProvider = (props) => {
  const [cookies, setCookiesState] = useState({
    functional: true,
    performance: true,
    marketing: true,
  });

  const setCookies = (value) => {
    setCookiesState({
      functional: value.functional,
      performance: value.performance,
      marketing: value.marketing,
    });
  };

  return (
    <CookieContext.Provider value={[cookies, setCookies]}>
      {props.children}
    </CookieContext.Provider>
  );
};
