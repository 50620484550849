import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BootstrapButton from "react-bootstrap/Button";

const Button = ({
  icon,
  className,
  children,
  variant,
  outline,
  orientation,
  noPadding,
  iconSpin,
  ...rest
}) => {
  let buttonPadding = "pr-2";
  let spaceBetweenIconAndChildren = " ml-2";
  let orderIcon = "order-3";
  let orderChildren = "order-1";

  if (orientation === "left") {
    buttonPadding = "pl-2";
    orderIcon = "order-1";
    orderChildren = "order-3";
  }

  if (noPadding) {
    buttonPadding = "p-0";
    spaceBetweenIconAndChildren = "";
  }

  return (
    <BootstrapButton
      className={`${
        icon ? buttonPadding : ""
      } d-inline-flex align-items-center${className ? " " + className : ""}`}
      variant={outline ? `outline-${variant}` : variant}
      {...rest}
    >
      {icon && (
        <>
          <div
            className={`${orderIcon} d-inline-flex align-items-center justify-content-center`}
            style={{ height: 24, width: 24 }}
          >
            <FontAwesomeIcon icon={icon} spin={iconSpin} />
          </div>
          <div className={`order-2${spaceBetweenIconAndChildren}`}></div>
        </>
      )}
      <div className={`${orderChildren}`}>{children}</div>
    </BootstrapButton>
  );
};
export default Button;
