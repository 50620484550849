import React from "react";

import HtmlHeader from "utils/HtmlHeader";
import Section from "components/common/Section";
import Button from "components/common/Button";
import Link from "components/common/Link";
import useWindowSize from "hooks/useWindowSize";

import { ReactComponent as NotFoundIllustration } from "assets/illustrations/page_not_found.svg";
import { ReactComponent as ServerDown } from "assets/illustrations/server_down.svg";

const ErrorPage = ({ error }) => {
  let errorHtmlTitle = "Fehler";
  let errorMessage = "Es ist ein unbekannter Fehler aufgetreten";
  let ErrorIllustration = ServerDown;
  if (error === "not-found") {
    errorHtmlTitle = "404";
    errorMessage = "Die Seite wurde nicht gefunden";
    ErrorIllustration = NotFoundIllustration;
  }
  const size = useWindowSize();
  return (
    <>
      <HtmlHeader title={errorHtmlTitle} />
      <Section spacing="large" className="text-center">
        <ErrorIllustration
          className="illustration mb-5"
          style={size.width ? { maxWidth: size.width - 80 } : {}}
        />
        <h1>{errorMessage}</h1>
        {error === "not-found" ? (
          <Button
            as={Link}
            to="/"
            newTab={false}
            icon="angle-right"
            variant="light"
            outline={false}
            className="mt-4"
          >
            Zur Startseite
          </Button>
        ) : (
          <Button
            icon="redo"
            variant="light"
            outline={false}
            className="mt-4"
            onClick={() => {
              window.location.reload();
            }}
          >
            Neu laden
          </Button>
        )}
      </Section>
    </>
  );
};

export default ErrorPage;
