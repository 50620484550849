import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "components/common/Button";
import Link from "components/common/Link";
import ResponsiveImage from "components/common/ResponsiveImage";
import Markdown from "components/common/Markdown";

const ImageWithText = ({ data }) => {
  let imagePosition = "order-1";
  let textPosition = "order-2";
  if (data.image_orientation === "right") {
    imagePosition = "order-2";
    textPosition = "order-1";
  }
  return (
    <Row className="d-flex no-gutters">
      <Col md="6" className={imagePosition}>
        {data.image && (
          <ResponsiveImage
            image={data.image}
            cover
            overlay
            className="bst-hero-img"
          />
        )}
      </Col>
      <Col md={6} className={`d-flex ${textPosition}`}>
        <div
          className={`bst-hero-txt-${data.image_orientation} text-lg-${data.image_orientation}`}
        >
          <h3>{data.title}</h3>
          <Markdown content={data.description}></Markdown>
          {data.button && (
            <Button
              as={Link}
              to={data.button.link}
              newTab={data.button.new_tab}
              icon={data.button.icon}
              variant={data.button.variant}
              outline={data.button.outline}
              className="mt-4"
            >
              {data.button.label}
            </Button>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ImageWithText;
