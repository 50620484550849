import React from "react";

import Image from "components/page/header/Image";
import LargeTitle from "components/page/header/LargeTitle";
import Carousel from "components/page/header/Carousel";

const HeaderComponent = ({ data }) => (
  <>
    {
      {
        "header.image": <Image data={data} />,
        "header.large-title": <LargeTitle data={data} />,
        "header.carousel": <Carousel data={data} />,
      }[data.__component]
    }
  </>
);

export default HeaderComponent;
