import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "router";
import FontawesomeInitializer from "utils/FontawesomeInitializer";
import Matomo from "utils/Matomo";
import Navigation from "components/navigation/Navigation";
import Footer from "components/footer/Footer";
import { CookieProvider } from "contexts/CookieContext";
import CookieAlert from "components/page/CookieAlert";

FontawesomeInitializer.init();

function App() {
  new Matomo({
    url: process.env.REACT_APP_MATOMO_URL,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    enableLinkTracking: true,
  });
  return (
    <Router>
      <CookieProvider>
        <Navigation />
        <Routes />
        <Footer />
        <CookieAlert />
      </CookieProvider>
    </Router>
  );
}

export default App;
