import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Markdown from "components/common/Markdown";
import Button from "components/common/Button";
import Link from "components/common/Link";
import ResponsiveImage from "components/common/ResponsiveImage";

const TextWithImage = ({ data }) => (
  <Row className="d-flex">
    <Col
      lg={5}
      className="d-flex justify-content-center align-items-center order-lg-2"
    >
      {data.image && (
        <ResponsiveImage
          image={data.image}
          maxHeight={200}
          className="mb-5 mb-lg-0"
        />
      )}
    </Col>
    <Col lg={7} className="order-lg-1">
      <h2>{data.title}</h2>
      <Markdown content={data.text} />
      {data.button && (
        <Button
          as={Link}
          to={data.button.link}
          newTab={data.button.new_tab}
          icon={data.button.icon}
          variant={data.button.variant}
          outline={data.button.outline}
          className="mt-4"
        >
          {data.button.label}
        </Button>
      )}
    </Col>
  </Row>
);

export default TextWithImage;
