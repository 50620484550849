const get = (path, signal, setResponse) => {
  const apiUrl = process.env.REACT_APP_CMS_URL + path;
  const fetchPage = async (signal) => {
    try {
      // function timeout(ms) {
      //   return new Promise((resolve) => setTimeout(resolve, ms));
      // }
      // await timeout(3000);
      const results = await fetch(apiUrl, {
        signal: signal,
      });
      const data = results.ok ? await results.json() : { error: "not-found" };
      setResponse(data);
    } catch (e) {
      console.error(e);
      setResponse({ error: "error" });
    }
  };
  fetchPage(signal);
};

const post = (path, body /*, signal*/, setResponse) => {
  const apiUrl = process.env.REACT_APP_CMS_URL + path;
  const postData = async (/*signal*/) => {
    try {
      // function timeout(ms) {
      //   return new Promise((resolve) => setTimeout(resolve, ms));
      // }
      // await timeout(5000);
      const results = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        //signal: signal,
      });
      const jsonResult = await results.json();
      const data = results.ok
        ? jsonResult
        : {
            error: `${jsonResult.message}${
              jsonResult.data
                ? ` - ${JSON.stringify(jsonResult.data.errors)}`
                : ""
            }`,
          };

      setResponse(data);
    } catch (e) {
      console.error(e);
      setResponse({ error: "error" });
    }
  };
  postData(/*signal*/);
};

export { get, post };
