import React from "react";
import BootstrapCarousel from "react-bootstrap/Carousel";

import ResponsiveImage from "components/common/ResponsiveImage";
import Button from "components/common/Button";
import Link from "components/common/Link";

const Carousel = ({ data }) => (
  <header>
    <BootstrapCarousel>
      {data.items &&
        data.items.map((item) => (
          <BootstrapCarousel.Item key={item.id}>
            {item.image && (
              <ResponsiveImage
                image={item.image}
                cover
                overlay
                className="bst-header-img d-flex align-items-center"
              >
                <div className="carousel-flex-container center-container">
                  <BootstrapCarousel.Caption className="bst-header-caption center-caption">
                    <h1>{item.title}</h1>
                    <p className="lead">{item.description}</p>
                    {item.button && (
                      <Button
                        as={Link}
                        to={item.button.link}
                        newTab={item.button.new_tab}
                        icon={item.button.icon}
                        variant={item.button.variant}
                        outline={item.button.outline}
                        className="btn-autosize mt-4"
                      >
                        {item.button.label}
                      </Button>
                    )}
                  </BootstrapCarousel.Caption>
                </div>
              </ResponsiveImage>
            )}
          </BootstrapCarousel.Item>
        ))}
    </BootstrapCarousel>
  </header>
);

export default Carousel;
