import React, { useState, useCallback, useEffect } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

import useWindowSize from "hooks/useWindowSize";

const PhotoGallery = ({ data }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const size = useWindowSize();
  useEffect(() => {
    if (data.images) {
      setPhotos(
        data.images.map((image) => {
          let photoSrc = `${process.env.REACT_APP_CMS_URL}${image.url}`;
          if (image.formats) {
            if (size.width < 768) {
              photoSrc = `${process.env.REACT_APP_CMS_URL}${image.formats.small.url}`;
            } else if (size.width < 1200) {
              photoSrc = `${process.env.REACT_APP_CMS_URL}${image.formats.medium.url}`;
            } else {
              photoSrc = `${process.env.REACT_APP_CMS_URL}${image.formats.large.url}`;
            }
          }
          return {
            src: photoSrc,
            width: image.width,
            height: image.height,
          };
        })
      );
    }
  }, [size.width, data]);
  return (
    <div>
      <Gallery
        photos={photos}
        onClick={openLightbox}
        direction={data.direction}
      />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
              styles={{
                headerClose: (base) => ({
                  ...base,
                  ":focus": { outline: 0 },
                }),
                headerFullscreen: (base) => ({
                  ...base,
                  ":focus": { outline: 0 },
                }),
                navigationPrev: (base) => ({
                  ...base,
                  ":focus": { outline: 0 },
                }),
                navigationNext: (base) => ({
                  ...base,
                  ":focus": { outline: 0 },
                }),
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default PhotoGallery;
