import React from "react";

import ResponsiveImage from "components/common/ResponsiveImage";

const Image = ({ data }) => (
  <header>
    {data.image && (
      <ResponsiveImage
        image={data.image}
        cover
        overlay
        className="bst-header-img d-flex align-items-center"
      >
        <div className="bst-header-flex-container center-container">
          <div className="bst-header-caption center-caption">
            <h1>{data.title}</h1>
          </div>
        </div>
      </ResponsiveImage>
    )}
  </header>
);

export default Image;
