import React from "react";
import Container from "react-bootstrap/Container";

const Section = ({
  children,
  background,
  spacing,
  border,
  className,
  noContainer,
  ...rest
}) => {
  let classNameValue = background ? `bst-section-${background.color}` : "";
  classNameValue = spacing
    ? `${classNameValue} bst-section-${spacing}`
    : classNameValue;
  if (border) {
    let borderOptions = border.split(" ");
    borderOptions = borderOptions.map(
      (borderOption) => `bst-border-${borderOption}`
    );
    classNameValue += " " + borderOptions.join(" ");
  }
  if (className) classNameValue += " " + className;
  return (
    <section className={classNameValue} {...rest}>
      {noContainer ? children : <Container>{children}</Container>}
    </section>
  );
};

export default Section;
