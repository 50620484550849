import React from "react";
import { Link as ReactLink, NavLink } from "react-router-dom";

const Link = ({ navLink, children, ...rest }) => {
  const LinkComponent = ({ newTab, children, ...rest }) => {
    let tabSetting;
    if (newTab) {
      tabSetting = { target: "_blank", rel: "noreferrer noopener" };
    }
    if (navLink) {
      return (
        <NavLink exact {...tabSetting} {...rest}>
          {children}
        </NavLink>
      );
    } else {
      return (
        <ReactLink {...tabSetting} {...rest}>
          {children}
        </ReactLink>
      );
    }
  };
  return <LinkComponent {...rest}>{children}</LinkComponent>;
};

export default Link;
