import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

const _init = () => {
  // Fontawesome Global Use Icons
  library.add(fas);
};

const fontawesome = { init: _init };
export default fontawesome;
