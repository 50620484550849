import React, { useEffect, useState } from "react";
import Image from "react-bootstrap/Image";
import Skeleton from "react-loading-skeleton";

import useWindowSize from "hooks/useWindowSize";

import icon from "assets/logo/icon-grey.svg";

const ResponsiveImage = ({
  image,
  cover,
  overlay,
  maxHeight,
  className,
  children,
}) => {
  const [imageUrl, setImageUrl] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  const size = useWindowSize();
  useEffect(() => {
    if (!image.formats) {
      setImageUrl(`${process.env.REACT_APP_CMS_URL}${image.url}`);
    } else {
      if (size.width < 768) {
        setImageUrl(
          `${process.env.REACT_APP_CMS_URL}${image.formats.small.url}`
        );
      } else if (size.width < 1200) {
        setImageUrl(
          `${process.env.REACT_APP_CMS_URL}${image.formats.medium.url}`
        );
      } else {
        setImageUrl(
          `${process.env.REACT_APP_CMS_URL}${image.formats.large.url}`
        );
      }
    }
  }, [size.width, image]);
  if (!className) className = "";
  return (
    <>
      {cover ? (
        <div
          className={`h-100 ${className}`}
          style={{
            backgroundImage: `url(${imageUrl}), url(${icon})`,
          }}
        >
          {overlay && <div className="h-100 overlay"></div>}
          {children}
        </div>
      ) : (
        <>
          <Image
            src={imageUrl}
            alt={image.alternativeText}
            style={{
              maxHeight: maxHeight || 100,
              maxWidth: size.width,
              display: imageLoaded ? "inline" : "none",
            }}
            onLoad={handleImageLoaded}
            className={className}
          />
          {!imageLoaded ? (
            <div className={`w-100 ${className}`}>
              <Skeleton height={maxHeight || 100} style={{ minWidth: 150 }} />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default ResponsiveImage;
