import React from "react";
import BootstrapBadge from "react-bootstrap/Badge";

const Badge = ({ label, variant, pill, ...rest }) => (
  <BootstrapBadge variant={variant} pill={pill} {...rest}>
    {label}
  </BootstrapBadge>
);

export default Badge;
