import React from "react";

import Badge from "components/common/Badge";
import Button from "components/common/Button";
import Link from "components/common/Link";

const Info = ({ data }) => (
  <div className="text-center d-flex flex-column flex-md-row align-items-center justify-content-center">
    {data.badge && (
      <Badge
        label={data.badge.label}
        variant={data.badge.variant}
        pill={data.badge.pill}
        className="mb-3 mb-md-0 mr-md-2"
      />
    )}
    {data.text}
    {data.button && (
      <Button
        as={Link}
        to={data.button.link}
        newTab={data.button.new_tab}
        icon={data.button.icon}
        variant={data.button.variant}
        outline={data.button.outline}
        noPadding={true}
        className="mt-1 mt-md-0 ml-md-2"
      >
        {data.button.label}
      </Button>
    )}
  </div>
);

export default Info;
