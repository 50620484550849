import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';

import { get } from 'utils/CmsConnection';
import HtmlHeader from 'utils/HtmlHeader';
import Loading from 'components/page/Loading';
import BodyComponent from 'components/page/BodyComponent';
import HeaderComponent from 'components/page/HeaderComponent';
import ErrorPage from 'ErrorPage';

const Page = ({ match }) => {
  const pageSlug = match.params.page ? match.params.page : 'startseite';

  const [pageContent, setPageContent] = useState({});
  const [loadingProgress, setLoadingProgress] = useState(0);

  useEffect(() => {
    //start loading animation
    setLoadingProgress(15);

    const abortController = new AbortController();
    const signal = abortController.signal;
    get('/pages/' + pageSlug, signal, (result) => {
      // complete progress bar
      setLoadingProgress(100);
      // scroll to top
      window.scrollTo(0, 0);
      // update page content
      setPageContent(result);
    });

    return function cleanup() {
      abortController.abort();
    };
  }, [pageSlug]);

  if (pageContent.error) return <ErrorPage error={pageContent.error} />;

  return (
    <>
      <LoadingBar
        color={process.env.REACT_APP_PRIMARY_COLOR}
        progress={loadingProgress}
        waitingTime={600}
        onLoaderFinished={() => setLoadingProgress(0)}
      />
      {pageContent.id ? (
        <HtmlHeader
          title={pageContent.title}
          description={pageContent.description}
          image={pageContent.image ? pageContent.image.formats.small.url : null}
        />
      ) : null}
      <div>
        {!pageContent.id ? (
          <Loading />
        ) : (
          <>
            {/* Header */}
            {pageContent.header &&
              pageContent.header.map((headerItem, index) => (
                <HeaderComponent key={index} data={headerItem} />
              ))}
            {/* Body */}
            {pageContent.content &&
              pageContent.content.map((bodyItem, index) => (
                <BodyComponent key={index} data={bodyItem} />
              ))}
          </>
        )}
      </div>
    </>
  );
};

export default Page;
