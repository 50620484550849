import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";

const List = ({ data }) => (
  <Row>
    <Col lg={4} className="mb-4 mb-lg-0 text-center text-lg-left">
      <h2>{data.title}</h2>
      {data.subtitle && <p className="mb-0 lead">{data.subtitle}</p>}
    </Col>
    <Col lg={8}>
      {data.items && (
        <ListGroup>
          {data.items.map((item, index) => (
            <ListGroup.Item key={index}>{item}</ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Col>
  </Row>
);

export default List;
