import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";

import yupLocaleDe from "utils/yupLocaleDe";
import { post } from "utils/CmsConnection";
import Button from "components/common/Button";
import Link from "components/common/Link";
import Markdown from "components/common/Markdown";

const ContactForm = ({ data }) => {
  const [isSending, setSending] = useState(false);
  const [apiResponse, setApiResponse] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const sendContactForm = (values) => {
    return new Promise(async (resolve) => {
      post("/inquiries", values, (data) => {
        if (mounted.current) {
          setApiResponse(data);
        }
        resolve();
      });
    });
  };

  yup.setLocale(yupLocaleDe);
  const schema = yup.object().shape({
    company: yup.string().label("Firma"),
    name: yup.string().required().label("Name"),
    mail: yup.string().email().required().label("E-Mail"),
    phone: yup.string().min(4).label("Telefon"),
    message: yup.string().required().label("Nachricht"),
    privacy: yup
      .boolean()
      .oneOf([true], "Sie müssen der Datenschutzerklärung zustimmen"),
  });

  const formik = useFormik({
    validationSchema: schema,
    onSubmit: (values) => {
      setSending(true);
      sendContactForm(values).then(() => {
        if (mounted.current) {
          setSending(false);
        }
      });
    },
    initialValues: {
      company: "",
      name: "",
      mail: "",
      phone: "",
      message: "",
      privacy: false,
    },
  });

  useEffect(() => {
    if (apiResponse.error) {
      setShowSuccessMessage(false);
      setErrorMessage(apiResponse.error);
      setShowErrorMessage(true);
      formik.setFieldValue("privacy", false);
      formik.setFieldTouched("privacy", false);
    } else if (apiResponse.id) {
      setErrorMessage("");
      setShowErrorMessage(false);
      setShowSuccessMessage(true);
      formik.handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResponse]);

  return (
    <>
      {!showSuccessMessage && <h2 className="mb-4">{data.title}</h2>}
      <Modal
        show={showErrorMessage}
        onHide={() => setShowErrorMessage(false)}
        aria-labelledby="error-modal-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="error-modal-title">
            Es ist ein Fehler aufgetreten
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className={errorMessage === "" ? "mb-0" : ""}>
            Beim Absenden des Kontaktformulars ist ein Fehler aufgetreten. Bitte
            versuchen Sie es erneut.
          </p>
          {errorMessage && <p className="mb-0">{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowErrorMessage(false)}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
      {showSuccessMessage && (
        <Row className="d-flex justify-content-center my-3">
          <Col sm={11} md={10} lg={9} xl={8}>
            <Card>
              <Card.Body>
                <div className="text-center mt-2 mb-4">
                  <h3>{data.success_title}</h3>
                  <Markdown content={data.success_description}></Markdown>
                </div>
                <div className="d-flex justify-content-center">
                  {data.success_button && (
                    <Button
                      as={Link}
                      to={data.success_button.link}
                      newTab={data.success_button.new_tab}
                      icon={data.success_button.icon}
                      variant={data.success_button.variant}
                      outline={data.success_button.outline}
                    >
                      {data.success_button.label}
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {/* Begin form */}
      {!showSuccessMessage && (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group as={Row} controlId="formCompany">
            <Form.Label column md="2">
              Firma
            </Form.Label>
            <Col md={10}>
              <Form.Control
                type="text"
                name="company"
                placeholder="Firma"
                value={formik.values.company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.company && !formik.errors.company}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formName">
            <Form.Label column md="2">
              Name*
            </Form.Label>
            <Col md={10}>
              <Form.Control
                type="text"
                name="name"
                placeholder="Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.name && !formik.errors.name}
                isInvalid={formik.touched.name && !!formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formMail">
            <Form.Label column md="2">
              E-Mail*
            </Form.Label>
            <Col md={10}>
              <Form.Control
                type="email"
                name="mail"
                placeholder="E-Mail-Adresse"
                value={formik.values.mail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.mail && !formik.errors.mail}
                isInvalid={formik.touched.mail && !!formik.errors.mail}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.mail}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formPhone">
            <Form.Label column md="2">
              Telefon
            </Form.Label>
            <Col md={10}>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Telefonnummer"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.phone && !formik.errors.phone}
                isInvalid={formik.touched.phone && !!formik.errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.phone}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formMessage">
            <Form.Label column md="2">
              Nachricht*
            </Form.Label>
            <Col md={10}>
              <Form.Control
                as="textarea"
                rows="3"
                name="message"
                value={formik.values.message}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isValid={formik.touched.message && !formik.errors.message}
                isInvalid={formik.touched.message && !!formik.errors.message}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.message}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formPrivacy">
            <Form.Label column md="2">
              Datenschutz*
            </Form.Label>
            <Col md={10} className="mt-2">
              <Form.Check
                custom
                type="checkbox"
                name="privacy"
                label={
                  <>
                    Es gilt die{" "}
                    <Link to="/datenschutz" newTab>
                      Datenschutzerklärung
                    </Link>
                  </>
                }
                checked={formik.values.privacy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.privacy && !!formik.errors.privacy}
                feedback={formik.errors.privacy}
              />
            </Col>
          </Form.Group>

          <Row className="d-flex justify-content-end">
            <Col md={10}>
              <Button
                variant="light"
                icon={!isSending ? "paper-plane" : "cog"}
                iconSpin={isSending}
                disabled={isSending}
                type={!isSending ? "submit" : "button"}
              >
                {isSending ? "Senden…" : "Absenden"}
              </Button>
            </Col>
          </Row>
          <Row className="d-flex justify-content-end mt-2">
            <Col md={10}>
              <small className="text-muted">
                <span className="font-weight-bold">Hinweis:</span> Bitte die mit
                * gekennzeichneten Felder ausfüllen.
              </small>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default ContactForm;
