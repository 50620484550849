import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "components/common/Button";
import Link from "components/common/Link";
import Markdown from "components/common/Markdown";

const IconContainer = ({ data }) => (
  <>
    <Row className="d-flex justify-content-center mb-4">
      <Col md={8} lg={6} xl={5} className="text-center">
        <FontAwesomeIcon icon={data.icon} size="5x" className="mb-4" />
        <h2>{data.title}</h2>
        <Markdown content={data.description}></Markdown>
      </Col>
    </Row>
    {data.button && (
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            as={Link}
            to={data.button.link}
            newTab={data.button.new_tab}
            icon={data.button.icon}
            variant={data.button.variant}
            outline={data.button.outline}
          >
            {data.button.label}
          </Button>
        </Col>
      </Row>
    )}
  </>
);

export default IconContainer;
