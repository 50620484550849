import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "components/common/Button";
import Link from "components/common/Link";
import Markdown from "components/common/Markdown";

const IconCollection = ({ data }) => (
  <>
    <Row>
      <Col className="text-center">
        {data.title && <h2>{data.title}</h2>}
        {data.subtitle && <p className="lead">{data.subtitle}</p>}
      </Col>
    </Row>
    {data.items && (
      <Row className="my-4 text-center">
        {data.items.map((item) => (
          <Col key={item.id} md={12 / data.items.length} className="my-2">
            <FontAwesomeIcon icon={item.icon} size="4x" className="mb-3" />
            <h2>{item.title}</h2>
            <Markdown content={item.description}></Markdown>
          </Col>
        ))}
      </Row>
    )}
    {data.button && (
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            as={Link}
            to={data.button.link}
            newTab={data.button.new_tab}
            icon={data.button.icon}
            variant={data.button.variant}
            outline={data.button.outline}
          >
            {data.button.label}
          </Button>
        </Col>
      </Row>
    )}
  </>
);

export default IconCollection;
